<template>
  <div class="index">
    <Navigation :path="path" />
    <div class="xl hidden-xs-only">
      <div class="banner">
        <el-carousel>
          <el-carousel-item v-for="item in topImg" :key="item.id">
            <img :src="item.image" @click="Jump(item)" />
          </el-carousel-item>
        </el-carousel>
        <img src="~img/index/01.png" class="mour" @click="$router.push({path:'/news'})" />
      </div>
    </div>
    <div class="sm hidden-sm-and-up">
      <van-swipe class="my-swipe" :autoplay="3000">
        <van-swipe-item v-for="item in topImg" :key="item.id">
          <img :src="item.image" @click="Jump(item)" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="one">
      <div class="title-box">
        <img src="~img/index/02.png" class="title-icon" />
        <h4>爱因斯坦</h4>
        <img src="~img/index/03.png" class="title-icon" />
      </div>
      <ul>
        <li>
          <img src="~img/index/04.png" />
          <div class="title">
            <div class="shu"></div>
            <p class="p1">爱因斯坦简介</p>
            <p class="p2">(1879.3.14-1955.4.18)</p>
          </div>
          <p class="conten">
            美籍德裔犹太人，毕业于瑞士苏黎世联邦理工学院，著名的科学家、物理学家、20世纪最伟大的科学家、思想家。
          </p>
          <p class="conten">
            人类历史上最具创造性才智的人物之一，被《时代周刊》评选为“世纪伟人”。
          </p>
          <p class="conten">
            开创了物理学的四个领域：狭义相对论、广义相对论、宇宙学和统一场论。
          </p>
        </li>
        <li>
          <img src="~img/index/05.png" />
          <div class="title">
            <div class="shu"></div>
            <p class="p1">爱因斯坦思维科学馆</p>
            <p class="p2">简介</p>
          </div>
          <p class="conten">
            3-12岁科学·思维融合教育开创者。从孩子的好奇心出发，以语言思考力、创意思考力为基础，激发孩子科学探究兴趣，培养孩子专注力、自主学习能力、创意问题解决能力、团队协作能力等综合素养。
          </p>
          <p class="conten">
            响应《全民科学素质行动规划纲要（2021-2035年）》青少年科学素质提升行动号召，激发青少年好奇心和想象力，增强科学兴趣、创新意识和创新能力，培育一大批具备科学家潜质的青少年群体，为加快建设科技强国夯实人才基础。
          </p>
        </li>
      </ul>
    </div>
    <div class="two">
      <div class="title-box">
        <img src="~img/index/02.png" class="title-icon" />
        <h4>产品历史&品牌背景</h4>
        <img src="~img/index/03.png" class="title-icon" />
      </div>
      <div class="pc-time hidden-xs-only">
        <div class="left">
          <div class="year">
            <p>2019</p>
            <div></div>
          </div>
          <ul>
            <li>
              <div class="box">
                <div class="time-title">
                  <img src="~img/index/07.png" class="icon" />
                  <p class="p1">2019年07月</p>
                </div>
                <p class="p2">
                  科创星球旗下第一子品牌：爱因斯坦思维科学馆 成立
                </p>
                <img src="~img/index/08.png" class="conimg img08" />
              </div>
            </li>
            <li>
              <div class="box">
                <div class="time-title">
                  <img src="~img/index/07.png" class="icon" />
                  <p class="p1">2019年11月</p>
                </div>
                <p class="p2">
                  自主研发科学教育信息化平台，形成科学教育OMO 模式闭环
                </p>
                <img src="~img/index/09.png" class="conimg img09" />
              </div>
            </li>
            <li>
              <div class="box">
                <div class="time-title">
                  <img src="~img/index/10.png" class="icon" />
                  <p class="p1 ye">2020年08月</p>
                </div>
                <p class="p2">
                  爱因斯坦思维科学馆北京城市副中心万达旗舰馆 筹建
                </p>
                <img src="~img/index/34.png" class="conimg img34" />

              </div>
            </li>
            <li>
              <div class="box">
                <div class="time-title">
                  <img src="~img/index/10.png" class="icon" />
                  <p class="p1 ye">2020年11月</p>
                </div>
                <p class="p2">
                  爱因斯坦思维科学馆品牌成功布局江苏、江西、河南等教育大省
                </p>
              </div>
            </li>
          </ul>
          <div class="year">
            <p class="blu">2021</p>
            <div class="blue"></div>
          </div>
          <ul>
            <li>
              <div class="box">
                <div class="time-title">
                  <img src="~img/index/11.png" class="icon" />
                  <p class="p1 blue">2021年03月</p>
                </div>
                <p class="p2">推出第二子品牌：</p>
                <p class="p3">
                  Ask why儿童科学课程，赋能幼儿机构开展科学 启蒙与探索教育
                </p>
                <img src="~img/index/12.png" class="conimg img12" />
              </div>
            </li>
            <li>
              <div class="box">
                <div class="time-title">
                  <img src="~img/index/11.png" class="icon" />
                  <p class="p1 blue">2021年09月</p>
                </div>
                <p class="p2">成功承办2021全国科普日北京通州主场活动</p>
                <img src="~img/index/13.png" class="conimg img12" />
              </div>
            </li>
            <li>
              <div class="box">
                <div class="time-title">
                  <img src="~img/index/11.png" class="icon" />
                  <p class="p1 blue">2021年10月</p>
                </div>
                <p class="p2">推出第四子品牌：</p>
                <p class="p3">
                  爱科数科学课程，致力于成为学校课后延时服务 内容的有益补充
                </p>
                <img src="~img/index/14.png" class="conimg img12" />
              </div>
            </li>
          </ul>
        </div>
        <img src="~img/index/06.png" class="zhou" />
        <div class="right">
          <ul>
            <li>
              <div class="box">
                <div class="time-title">
                  <img src="~img/index/07.png" class="icon" />
                  <p class="p1">2019年06月</p>
                </div>
                <p class="p2">科创星球 成立</p>
                <img src="~img/index/15.png" class="conimg img15" />
              </div>
            </li>
            <li>
              <div class="box">
                <div class="time-title">
                  <img src="~img/index/07.png" class="icon" />
                  <p class="p1">2019年10月</p>
                </div>
                <p class="p2">
                  爱因斯坦教育研究院汇聚国内外专家教授与学者，立足国情，整合了国际上先进的STEAM教育理念，结合国内科学课程标准，进行课程体系全面升级
                </p>
                <img src="~img/index/16.png" class="conimg img16" />
              </div>
            </li>
            <li>
              <div class="box">
                <div class="time-title">
                  <img src="~img/index/07.png" class="icon" />
                  <p class="p1">2019年12月</p>
                </div>
                <p class="p2">
                  荣膺腾讯新闻、新浪教育2019年度影响力 STEAM 教育品牌奖项
                </p>
                <div class="clearfix">
                  <img src="~img/index/17.png" class="conimg img17" />
                  <img src="~img/index/18.png" class="conimg img17" />
                </div>
              </div>
            </li>
          </ul>
          <div class="year bott">
            <p class="ye">2020</p>
            <div class="yes"></div>
          </div>
          <ul>
            <li>
              <div class="box">
                <div class="time-title">
                  <img src="~img/index/10.png" class="icon" />
                  <p class="p1 ye">2020年09月</p>
                </div>
                <p class="p2">北京十余家合作校陆续开课</p>
              </div>
            </li>
            <li>
              <div class="box">
                <div class="time-title">
                  <img src="~img/index/10.png" class="icon" />
                  <p class="p1 ye">2020年12月</p>
                </div>
                <p class="p2">荣膺腾讯教育2020年度影响力国际教育品牌奖项</p>
                <img src="~img/index/19.png" class="conimg img19" />
              </div>
            </li>
            <li>
              <div class="box">
                <div class="time-title">
                  <img src="~img/index/11.png" class="icon" />
                  <p class="p1 blue">2021年01月</p>
                </div>
                <p class="p2">
                  创始人胡俊高授命中国教育发展战略学会 教育教学 创新专业委员会
                  副秘书长
                </p>
              </div>
            </li>
            <li>
              <div class="box">
                <div class="time-title">
                  <img src="~img/index/11.png" class="icon" />
                  <p class="p1 blue">2021年08月</p>
                </div>
                <p class="p2">推出第三子品牌：</p>
                <p class="p3">
                  Ask what科学探究课程，赋能K12机构拓科转型 STEAM科学素质教育
                </p>
                <img src="~img/index/20.png" class="conimg img12" />
              </div>
            </li>
            <li>
              <div class="box">
                <div class="time-title">
                  <img src="~img/index/11.png" class="icon" />
                  <p class="p1 blue">2021年10月</p>
                </div>
                <p class="p2">
                  成功举办科学进校园系列活动、讲科学家故事弘扬科学家精神等活动
                </p>
                <img src="~img/index/21.png" class="conimg img12" />
              </div>
            </li>
          </ul>
          <img src="~img/index/22.png" class="qushi" />
        </div>
      </div>
      <div class="web-time hidden-sm-and-up">
        <img src="~img/index/23.png" />
      </div>
    </div>
    <div class="three">
      <div class="title-box">
        <img src="~img/index/02.png" class="title-icon" />
        <h4>我们的产品</h4>
        <img src="~img/index/03.png" class="title-icon" />
      </div>
      <ul class="list-box">
        <li>
          <img src="~img/index/24.png" />
          <p>专注 3-6 岁幼儿科学启蒙<br />以语言思考力为基础的英才教育课程</p>
        </li>
        <li>
          <img src="~img/index/25.png" />
          <p>
            专注 6-12 岁少儿科学探究以创意思考力为基础的<br />科学·思维融合教育课程
          </p>
        </li>
        <li>
          <img src="~img/index/26.png" />
          <p>敬请期待... ...</p>
        </li>
      </ul>
    </div>
    <div class="four">
      <div class="title-box">
        <img src="~img/index/02.png" class="title-icon" />
        <h4>选择我们的六大理由</h4>
        <img src="~img/index/03.png" class="title-icon" />
      </div>
      <ul>
        <li v-for="item in list" :key="item.id">
          <img :src="item.image" />
          <p class="p1">{{item.subtitle}}</p>
          <p class="p2">{{item.title}}</p>
          <p class="p3">{{item.abstract}}</p>
        </li>
      </ul>
    </div>
    <div class="five">
      <div class="title-box">
        <img src="~img/index/02.png" class="title-icon" />
        <h4>我们的荣誉</h4>
        <img src="~img/index/03.png" class="title-icon" />
      </div>
      <div class="xl hidden-xs-only">
        <div class="banner">
          <el-carousel>
            <el-carousel-item v-for="value in btnImg" :key="value.id">
              <img :src="value.image" @click="Jump(item)" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="sm hidden-sm-and-up">
        <van-swipe class="my-swipe" :autoplay="3000">
          <van-swipe-item v-for="value in btnImg" :key="value.id">
            <img :src="value.image" @click="Jump(item)" />
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div class="six">
      <div class="title-box">
        <img src="~img/index/02.png" class="title-icon" />
        <h4>国际化学术合作</h4>
        <img src="~img/index/03.png" class="title-icon" />
      </div>
      <ul>
        <li>
          <img src="~img/index/29.png" />
        </li>
        <li>
          <img src="~img/index/30.png" />
        </li>
        <li>
          <img src="~img/index/31.png" />
        </li>
      </ul>
    </div>
    <consulting />
    <foot />
  </div>
</template>


<script>
import "element-ui/lib/theme-chalk/display.css";
import Navigation from "@/components/header.vue";
import footer from "@/components/footer.vue";
import consulting from "@/components/consulting.vue";

export default {
  components: {
    Navigation,
    foot: footer,
    consulting,
  },
  data() {
    return {
      path: "/",
      topImg: [], // 顶部轮播图
      btnImg: [], // 底部轮播图
      list: [],
    };
  },
  created() {
    this.getbanner();
    this.getlist();
  },
  methods: {
    async getbanner() {
      const res = await this.axios.get("api/index/play");
      if (res.status == 200 && res.data.code == 1) {
        const { data } = res.data;
        this.topImg = data.filter((value) => value.type == "top");
        this.btnImg = data.filter((item) => item.type == "bottom");
      }
    },
    async getlist() {
      const res = await this.axios.get("api/index/reason");
      if (res.status == 200 && res.data.code == 1) {
        const { data } = res.data;
        this.list = data;
      }
    },
    Jump(value) {
      location.href = value.url;
    },
  },
};
</script>
<style lang="less" scoped>
.index {
  .xl {
    .banner {
      position: relative;
      .mour {
        position: absolute;
        width: 1.66rem;
        top: 0.34rem;
        right: 0;
        z-index: 9;
        cursor: pointer;
      }
    }
    /deep/.el-carousel {
      height: 9.27rem;
      .el-carousel__container {
        height: 9.27rem;
      }
      .el-carousel__item {
        height: 9.27rem;
      }
      img {
        height: 9.27rem;
      }
    }
  }
  // .sm {
  // }
  .title-box {
    display: flex;
    justify-content: center;
    align-items: center;
    .title-icon {
      width: 0.8rem;
      height: 0.38rem;
    }
    h4 {
      font-size: 0.45rem;
      line-height: 0.8rem;
      margin-left: 0.47rem;
      margin-right: 0.47rem;
    }
  }
  .one {
    padding-top: 1.07rem;
    ul {
      display: flex;
      justify-content: center;
      margin-top: 0.9rem;
      li {
        width: 6.64rem;
        .title {
          padding-top: 0.43rem;
          .shu {
            width: 0.1rem;
            height: 0.59rem;
            border-radius: 0.1rem;
            background: #f3ce4c;
            float: left;
            margin-right: 0.18rem;
          }
          .p1 {
            font-size: 0.25rem;
            color: #575757;
            line-height: 0.3rem;
          }
          .p2 {
            font-size: 0.18rem;
            color: #909090;
            margin-top: 0.06rem;
          }
        }
        .conten {
          font-size: 0.2rem;
          color: #575757;
          margin-top: 0.34rem;
        }
      }
      li:nth-child(1) {
        padding-right: 0.49rem;
      }
      li:nth-child(2) {
        padding-left: 0.49rem;
      }
    }
  }
  .two {
    padding-top: 1.42rem;
    .pc-time {
      display: flex;
      justify-content: center;
      margin-top: 1.04rem;
      .zhou {
        width: 0.16rem;
      }
      .left {
        width: 6.55rem;
        .year {
          padding-left: 1.22rem;
          padding-top: 0.55rem;
          font-size: 1.07rem;
          color: #e04a2e;
          font-weight: bolder;
          position: relative;
          line-height: 1.07rem;
          div {
            width: 2.44rem;
            height: 0.03rem;
            background: #e04a2e;
            position: absolute;
            right: 0;
            top: 1.06rem;
          }
          .blu {
            color: #3c80f0;
          }
          .blue {
            background: #3c80f0;
          }
        }
        ul {
          margin-bottom: 0.7rem;
          li {
            margin-top: 0.8rem;
            .box {
              position: relative;
              min-height: 2rem;
              width: 6.13rem;
              background: #f2f4f4;
              -moz-border-radius: 0.4rem;
              -webkit-border-radius: 0.4rem;
              border-radius: 0.4rem;
              padding: 0.22rem 0.7rem 0.22rem 0.27rem;
              .time-title {
                display: flex;
                align-items: center;
                .icon {
                  width: 0.33rem;
                  height: 0.19rem;
                  margin-right: 0.13rem;
                }
                .p1 {
                  font-size: 0.34rem;
                  font-weight: bolder;
                  color: #e04a2e;
                }

                .ye {
                  color: #e9aa3b;
                }
                .blue {
                  color: #3c80f0;
                }
              }
              .p2 {
                font-size: 0.22rem;
                color: #000000;
                margin-top: 0.22rem;
              }
              .p3 {
                font-size: 0.22rem;
                color: #000000;
              }
              .conimg {
                margin: auto;
                margin-top: 0.39rem;
              }
              .img08 {
                width: 3.36rem;
              }
              .img09 {
                width: 2.07rem;
              }
              .img12 {
                width: 5.02rem;
              }
            }
            .box:before {
              position: absolute;
              content: "";
              width: 0;
              height: 0;
              left: 100%;
              top: 50%;
              transform: translate(0, -50%);
              border-top: 0.13rem solid transparent;
              border-left: 0.18rem solid #f2f4f4;
              border-bottom: 0.13rem solid transparent;
            }
          }
        }
      }
      .right {
        width: 6.55rem;
        .year {
          padding-right: 1.22rem;
          padding-top: 0.55rem;
          font-size: 1.07rem;
          color: #e04a2e;
          font-weight: bolder;
          position: relative;
          line-height: 1.07rem;
          text-align: right;
          div {
            width: 2.44rem;
            height: 0.03rem;
            background: #e04a2e;
            position: absolute;
            left: 0;
            top: 1.06rem;
          }
          .blu {
            color: #3c80f0;
          }
          .blue {
            background: #3c80f0;
          }
          .ye {
            color: #e9aa3b;
          }
          .yes {
            background: #e9aa3b;
          }
        }
        .bott {
          margin-bottom: 0.5rem;
          padding-top: 0;
          div {
            top: 50%;
          }
        }
        ul {
          margin-bottom: 0.7rem;
          li {
            margin-bottom: 0.6rem;
            margin-left: 0.43rem;
            .box {
              position: relative;
              min-height: 2rem;
              width: 6.13rem;
              background: #f2f4f4;
              -moz-border-radius: 0.4rem;
              -webkit-border-radius: 0.4rem;
              border-radius: 0.4rem;
              padding: 0.22rem 0.7rem 0.22rem 0.27rem;
              .time-title {
                display: flex;
                align-items: center;
                .icon {
                  width: 0.33rem;
                  height: 0.19rem;
                  margin-right: 0.13rem;
                }
                .p1 {
                  font-size: 0.34rem;
                  font-weight: bolder;
                  color: #e04a2e;
                }

                .ye {
                  color: #e9aa3b;
                }
                .blue {
                  color: #3c80f0;
                }
              }
              .p2 {
                font-size: 0.22rem;
                color: #000000;
                margin-top: 0.22rem;
              }
              .p3 {
                font-size: 0.22rem;
                color: #000000;
              }
              .conimg {
                margin: auto;
                margin-top: 0.39rem;
              }
              .img08 {
                width: 3.36rem;
              }
              .img09 {
                width: 2.07rem;
              }
              .img12 {
                width: 5.02rem;
              }
              .img15 {
                width: 1.58rem;
                position: absolute;
                right: 0.73rem;
                top: 30%;
                transform: translate(0, -50%);
              }
              .img16 {
                width: 4.14rem;
              }
              .img17 {
                width: 1.58rem;
                float: right;
                margin-right: 0.23rem;
              }
              .img19 {
                width: 1.34rem;
              }
              .img34 {
                width: 4.15rem;
              }
            }
            .box:before {
              position: absolute;
              content: "";
              width: 0;
              height: 0;
              right: 100%;
              top: 50%;
              transform: translate(0, -50%);
              border-top: 0.13rem solid transparent;
              border-right: 0.18rem solid #f2f4f4;
              border-bottom: 0.13rem solid transparent;
            }
          }
        }
        .qushi {
          width: 5.37rem;
          margin: auto;
        }
      }
    }
    // .web-time {
    // }
  }
  .three {
    padding-top: 1rem /* 100/100 */;
    padding-bottom: 0.5rem;
    margin-top: 1rem /* 100/100 */;
    background: #eee;
    .list-box {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 1rem /* 100/100 */;
      li {
        width: 5rem /* 400/100 */;
        margin-bottom: 0.5rem;
        img {
          width: 2.35rem /* 235/100 */;
          margin: auto;
        }
        p {
          text-align: center;
          margin: auto;
          margin-top: 0.54rem /* 54/100 */;
          font-size: 0.2rem /* 20/100 */;
          // max-width: 3.4rem /* 300/100 */;
        }
      }
    }
  }
  .four {
    padding-top: 1rem /* 100/100 */;
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-top: 1rem /* 100/100 */;
      // padding: 0 2.62rem 2.67rem 0;
      padding-left: 2.62rem /* 262/100 */;
      padding-right: 2.67rem /* 267/100 */;
      li {
        width: 3.84rem /* 384/100 */;
        margin-bottom: 0.9rem /* 90/100 */;
        // img {
        // }
        .p1 {
          font-size: 0.18rem /* 18/100 */;
          color: #999999;
          line-height: 0.39rem /* 39/100 */;
        }
        .p2 {
          font-size: 0.25rem /* 25/100 */;
          color: #4f82e1;
          margin-bottom: 0.1rem /* 10/100 */;
        }
        .p3 {
          font-size: 0.2rem /* 20/100 */;
          color: #373737;
          line-height: 0.35rem /* 35/100 */;
        }
      }
    }
    @media (max-width: 750px) {
      ul {
        padding-left: 20px;
        padding-right: 20px;
        li {
          width: 80%;
          margin: auto;
          margin-bottom: 20px;
        }
      }
    }
  }
  .five {
    .xl {
      margin-top: 0.9rem;
      /deep/.el-carousel {
        height: 10.93rem /* 1093/100 */;
        .el-carousel__container {
          height: 10.93rem /* 1093/100 */;
        }
        .el-carousel__item {
          height: 10.93rem /* 1093/100 */;
        }
        img {
          height: 10.93rem /* 1093/100 */;
        }
      }
    }
  }
  .six {
    padding-top: 1rem /* 100/100 */;
    padding-bottom: 0.8rem /* 80/100 */;
    ul {
      padding-left: 2.5rem /* 250/100 */;
      padding-right: 2.5rem /* 250/100 */;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1rem /* 100/100 */;
      flex-wrap: wrap;
      li {
        width: 4.3rem /* 430/100 */;
      }
    }
    @media (max-width: 750px) {
      ul {
        padding-left: 20px;
        padding-right: 20px;
        li {
          width: 80%;
          margin: auto;
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>